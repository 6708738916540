"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.marketCodeChange = exports.hideSelf = exports.ageConditionalShow = exports.dropdownOptionConditionalShow = exports.conditionalShow = void 0;
var misc_1 = require("./misc");
var conditionalShow = function (field, target) {
    var value = field.value;
    var rules = target.split('|');
    rules.forEach(function (rule) {
        var _a = __read(rule.split(':'), 2), comparisonValue = _a[0], targetFieldName = _a[1];
        var targetField = field.form.fields.find(function (f) { return f.configuration.name === targetFieldName; });
        if (targetField && targetField.htmlElement) {
            if (comparisonValue === "" + value) {
                targetField.show();
            }
            else {
                targetField.hide();
            }
        }
    });
};
exports.conditionalShow = conditionalShow;
var dropdownOptionConditionalShow = function (field, target) {
    var value = field.value;
    target.forEach(function (rule) {
        var comparisonValue = rule.value, targetFieldName = rule.field, targetOptionCodes = rule.options;
        var targetField = field.form.fields.find(function (f) {
            return f.configuration.type === 'dropdown' && f.configuration.name === targetFieldName;
        });
        if (targetField && targetField.htmlElement) {
            if (comparisonValue === "" + value) {
                targetField.showOptions(targetOptionCodes);
            }
            else {
                targetField.hideOptions(targetOptionCodes);
            }
        }
    });
};
exports.dropdownOptionConditionalShow = dropdownOptionConditionalShow;
var ageConditionalShow = function (field, target) {
    var date = field.value;
    var age;
    if (date.year > 0 && date.month > 0 && date.day > 0) {
        age = (0, misc_1.getAge)(String(date.year), String(date.month), String(date.day));
    }
    else {
        age = -1;
    }
    var rules = target.split('|');
    rules.forEach(function (rule) {
        var _a = __read(rule.split(':'), 3), operator = _a[0], comparisonAgeString = _a[1], targetFieldName = _a[2];
        var comparisonAge = parseInt(comparisonAgeString, 10);
        var targetField = field.form.fields.find(function (f) { return f.configuration.name === targetFieldName; });
        if (targetField && targetField.htmlElement) {
            if (age === -1) {
                targetField.hide();
            }
            else {
                var comparison = void 0;
                switch (operator) {
                    case "<":
                        comparison = age < comparisonAge;
                        break;
                    case "<=":
                        comparison = age <= comparisonAge;
                        break;
                    case ">=":
                        comparison = age >= comparisonAge;
                        break;
                    case ">":
                        comparison = age > comparisonAge;
                        break;
                    default:
                        comparison = false;
                }
                if (comparison) {
                    targetField.show();
                }
                else {
                    targetField.hide();
                }
            }
        }
    });
};
exports.ageConditionalShow = ageConditionalShow;
var hideSelf = function (field, target) {
    if (/^length/.test(target)) {
        var length_1 = target.split(':')[1];
        var dropdown = field;
        var optionsWithValues = dropdown.configuration.options.filter(function (_a) {
            var code = _a.code;
            return !!code;
        });
        if (optionsWithValues.length <= +length_1) {
            dropdown.hide();
        }
    }
};
exports.hideSelf = hideSelf;
var marketCodeChange = function (field, target) {
    var form = field.form, value = field.value;
    var currentMarketCode = form.settings.marketCode;
    var pairs = target.split('|');
    pairs.forEach(function (pair) {
        var _a = __read(pair.split(':'), 2), fieldValue = _a[0], newMarketCode = _a[1];
        if (value === fieldValue && currentMarketCode !== newMarketCode) {
            form.changeMarketCode(newMarketCode);
        }
    });
};
exports.marketCodeChange = marketCodeChange;
