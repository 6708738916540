"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setOptionalArgument = void 0;
function setOptionalArgument(_a) {
    var argumentIndex = _a.argumentIndex, expectedType = _a.expectedType, fallbackValue = _a.fallbackValue;
    return function (target, propertyName, descriptor) {
        var method = descriptor.value;
        descriptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (typeof args[argumentIndex] !== expectedType) {
                args.splice(argumentIndex, 0, fallbackValue);
            }
            var result = method.apply(this, args);
            return result;
        };
        return descriptor;
    };
}
exports.setOptionalArgument = setOptionalArgument;
