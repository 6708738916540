"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLUGIN_LISTENER_NAME = void 0;
var misc_1 = require("../utils/misc");
exports.PLUGIN_LISTENER_NAME = 'Forms X CN addresses';
function showDaumPostcode(form) {
    var _a;
    var layer = document.getElementById('layer');
    if (!layer) {
        var layerWrapper = document.createElement('div');
        layerWrapper.setAttribute('id', 'layer-wrapper');
        layer = document.createElement('div');
        layer.setAttribute('id', 'layer');
        layer.setAttribute('style', "display:none; border:5px solid; position:fixed; width:350px; height:500px; z-index: 9999; top:15vh; left: " + ((0, misc_1.isMobile)() ? '10vw' : '40vw'));
        layerWrapper.appendChild(layer);
        var img = document.createElement('img');
        img.setAttribute('id', 'btnCloseLayer');
        img.setAttribute('src', '//i1.daumcdn.net/localimg/localimages/07/postcode/320/close.png');
        img.setAttribute('alt', '??');
        img.setAttribute('style', 'position: absolute ;right: -18px; top: -11px; background-color: #333; color: #fff; padding: 0; border-radius: 50%; width: 30px; height: 30px; text-align: center; font-size: 19px; z-index: 9999;');
        img.className = 'dynamicElement';
        layer.appendChild(img);
        document.getElementsByTagName('body')[0].appendChild(layerWrapper);
        (_a = document.getElementById('btnCloseLayer')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () {
            layer.style.display = 'none';
        });
    }
    new window.daum.Postcode({
        oncomplete: function (daumData) {
            var id = form.settings.id;
            var $postalCode = document.getElementById(id + "-postalCode");
            var $addressLine = document.getElementById(id + "-addressLine1");
            var $state = document.getElementById(id + "-state");
            $postalCode.value = daumData.zonecode;
            form.data.postalCode = daumData.zonecode;
            $addressLine.value = daumData.address;
            form.data.addressLine1 = daumData.address;
            (0, misc_1.triggerEvent)($addressLine, 'change');
            (0, misc_1.triggerEvent)($postalCode, 'change');
            var options = $state.querySelectorAll('option');
            for (var i = 0; i < options.length; i += 1) {
                if (daumData.address.indexOf(options[i].innerText) >= 0) {
                    var foundAddress = daumData.address.replace(options[i].innerText, '');
                    $addressLine.value = foundAddress;
                    form.data.addressLine1 = foundAddress;
                    $state.value = options[i].value;
                    form.data.state = options[i].value;
                    (0, misc_1.triggerEvent)($state, 'change');
                    break;
                }
            }
            layer.style.display = 'none';
            $postalCode.blur();
        },
        width: '100%',
        height: '100%',
    }).embed(layer);
    layer.style.display = 'block';
}
function waitForDaum(form, counter) {
    var _a, _b;
    if (counter === void 0) { counter = 0; }
    if (!window.daum) {
        if (counter > 15) {
            throw new Error('[Forms X KR Addr] Cannot load daum plugin in a reasonable amount of time. Aborting.');
        }
        setTimeout(function () { return waitForDaum(form, counter++); }, 200);
        return;
    }
    var findAddress = form.fields.find(function (_a) {
        var configuration = _a.configuration;
        return configuration.name === 'findAddress';
    });
    var postalCode = form.fields.find(function (_a) {
        var configuration = _a.configuration;
        return configuration.name === 'postalCode';
    });
    (_a = findAddress._inputElement) === null || _a === void 0 ? void 0 : _a.addEventListener('click', function () { return showDaumPostcode(form); });
    (_b = postalCode._inputElement) === null || _b === void 0 ? void 0 : _b.addEventListener('focus', function () { return showDaumPostcode(form); });
}
var callback = function (form) {
    form.on('formRendered', exports.PLUGIN_LISTENER_NAME, function () {
        waitForDaum(form);
    });
};
exports.default = callback;
