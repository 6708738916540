"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwError = exports.logInfo = exports.logWarning = exports.logError = exports.log = void 0;
var logLevels = {
    error: '#E53935',
    warning: '#FB8C00',
    info: '#2196F3',
    debug: '#000'
};
var log = function (msg, color) {
    if (color === void 0) { color = logLevels.debug; }
    var args = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
    }
    console.log("%c[EF Forms] " + msg, "color: " + color, args);
};
exports.log = log;
var logError = function (msg) { return (0, exports.log)(msg, logLevels.error); };
exports.logError = logError;
var logWarning = function (msg) { return (0, exports.log)(msg, logLevels.warning); };
exports.logWarning = logWarning;
var logInfo = function (msg) { return (0, exports.log)(msg, logLevels.info); };
exports.logInfo = logInfo;
var throwError = function (msg, err) {
    throw new Error("[EF Forms] " + msg + "\n" + (err ? err.stack : ''));
};
exports.throwError = throwError;
