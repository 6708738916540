"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAge = exports.getUrlParams = exports.emptyNode = exports.parseLabelAsPlaceholder = exports.isInViewport = exports.isMobile = exports.triggerEvent = exports.appendStyles = exports.appendScript = void 0;
var env_utils_1 = require("@ilc-technology/env-utils");
var appendScript = function (url) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        var absoluteUrl = "" + ((0, env_utils_1.isLocal)() && url.indexOf('//') !== 0 && url.indexOf('http') !== 0 ? 'https://www.ef.com' : '') + url;
        script.setAttribute('src', absoluteUrl);
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};
exports.appendScript = appendScript;
var appendStyles = function (styleFile) {
    return new Promise(function (resolve) {
        var newStyles = document.createElement('link');
        newStyles.setAttribute('href', styleFile);
        newStyles.setAttribute('rel', 'stylesheet');
        newStyles.onload = function () { return resolve(); };
        document.getElementsByTagName('head')[0].appendChild(newStyles);
    });
};
exports.appendStyles = appendStyles;
var triggerEvent = function (el, eventName, data) {
    if (data === void 0) { data = {}; }
    var event = new Event(eventName, { bubbles: true });
    event.data = data;
    el.dispatchEvent(event);
};
exports.triggerEvent = triggerEvent;
var isMobile = function () { return /Mobile|Android|BlackBerry/.test(navigator.userAgent); };
exports.isMobile = isMobile;
var isInViewport = function (el) {
    var _a = el.getBoundingClientRect(), bottom = _a.bottom, right = _a.right, left = _a.left, top = _a.top;
    return bottom > 0 && right > 0 && left < (innerWidth || document.documentElement.clientWidth) && top < (innerHeight || document.documentElement.clientHeight);
};
exports.isInViewport = isInViewport;
var parseLabelAsPlaceholder = function (fieldName, fieldType, lapConf) {
    return lapConf &&
        ((typeof lapConf === 'string' && (lapConf === 'all' || lapConf === fieldName || lapConf === fieldType)) ||
            (Array.isArray(lapConf) && (lapConf.indexOf(fieldName) >= 0 || lapConf.indexOf(fieldType) >= 0)));
};
exports.parseLabelAsPlaceholder = parseLabelAsPlaceholder;
var emptyNode = function (node) {
    var cNode = node.cloneNode(false);
    node.parentNode.replaceChild(cNode, node);
    return cNode;
};
exports.emptyNode = emptyNode;
var getUrlParams = function (url, separator) {
    if (url === void 0) { url = window.document.location.search; }
    if (separator === void 0) { separator = '?'; }
    var hashes = url.slice(url.indexOf(separator) + 1).split('&');
    var params = {};
    hashes.map(function (hash) {
        var _a = __read(hash.split('='), 2), key = _a[0], val = _a[1];
        params[key] = decodeURIComponent(val);
    });
    return params;
};
exports.getUrlParams = getUrlParams;
var getAge = function (year, month, day) {
    if (year.length > 4) {
        var splitYear = year.split('/');
        day = splitYear[0];
        month = splitYear[1];
        year = splitYear[2];
    }
    var formatToDoubleDigit = function (x) {
        if (Number(x) < 10) {
            return '0' + x;
        }
        return x;
    };
    var yearLength = 3.15576e10;
    if (month.length < 2) {
        month = formatToDoubleDigit(month);
    }
    if (day.length < 2) {
        day = formatToDoubleDigit(day);
    }
    var birthDate = year + "-" + month + "-" + day;
    return Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / yearLength);
};
exports.getAge = getAge;
