"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createErrorMessageNodes = exports.createElement = void 0;
var logger_1 = require("./logger");
function createElement(type, attrs) {
    var children = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        children[_i - 2] = arguments[_i];
    }
    var node = document.createElement(type);
    if (attrs) {
        Object.keys(attrs).forEach(function (attr) {
            if (typeof attrs[attr] !== 'undefined' && attrs[attr] !== null) {
                node.setAttribute(attr, attrs[attr].toString());
            }
        });
    }
    if (children) {
        children.forEach(function (child) {
            if (typeof child === 'string' || typeof child === 'number') {
                node.insertAdjacentHTML('beforeend', "" + child);
            }
            else if (child && child.nodeName) {
                node.appendChild(child);
            }
            else {
                (0, logger_1.log)("Can't append invalid child to element", '#aaa', node, child);
            }
        });
    }
    return node;
}
exports.createElement = createElement;
function createErrorMessageNodes(errorMessageWrapper, errorList) {
    var errorMessagesFragment = document.createDocumentFragment();
    errorList.forEach(function (message) {
        var errorNode = createElement('p', {
            class: 'ef-form-validation -is-invalid'
        }, message);
        errorMessagesFragment.appendChild(errorNode);
    });
    errorMessageWrapper.innerHTML = '';
    errorMessageWrapper.appendChild(errorMessagesFragment);
}
exports.createErrorMessageNodes = createErrorMessageNodes;
