"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getQQAllocationProgramCode = function (availablePrograms, ageBrackets, programCategory, age, courseLength, exchangeOrInternationalHighSchoolDiploma) {
    var getLangProgramCode = function () {
        if (!courseLength && age >= ageBrackets.high)
            return 'Multi';
        if (age < ageBrackets.high) {
            return availablePrograms.includes('ILSY') ? 'ILSY' : 'ILS';
        }
        else if (age >= ageBrackets.high && age < ageBrackets.uni) {
            if (['4', '16'].includes(courseLength)) {
                return availablePrograms.includes('ILSY') ? 'ILSY' : 'ILS';
            }
            else if (courseLength === '26') {
                return 'LY';
            }
        }
        else if (age >= ageBrackets.uni) {
            if (['4', '16'].includes(courseLength)) {
                return 'ILS';
            }
            else if (courseLength === '26') {
                return 'LY';
            }
        }
        return 'ILS';
    };
    var getHighProgramCode = function () {
        if (availablePrograms.includes('HSY')) {
            return exchangeOrInternationalHighSchoolDiploma === 'Yes' ? 'HSY' : 'IB';
        }
        return 'IB';
    };
    var programCode;
    switch (programCategory) {
        case 'LANG':
            programCode = getLangProgramCode();
            break;
        case 'LNGD':
            programCode = 'ILSD';
            break;
        case 'HIGH':
            programCode = getHighProgramCode();
            break;
        case 'UP':
            programCode = 'BC';
            break;
        case 'CORP':
            programCode = 'LOC';
            break;
        default:
            programCode = 'ILS';
    }
    return programCode;
};
exports.default = getQQAllocationProgramCode;
