"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var logger_1 = require("../utils/logger");
var Validators = __importStar(require("../utils/validators"));
var Listenable_1 = require("../utils/Listenable");
var BusinessRules = __importStar(require("../utils/businessRules"));
var misc_1 = require("../utils/misc");
var Field = (function (_super) {
    __extends(Field, _super);
    function Field() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._show = true;
        _this._validationRules = [];
        return _this;
    }
    Field.register = function (name, handler) {
        Field.handlers.push({
            name: name,
            handler: handler,
        });
    };
    Field.prototype.isRequired = function () {
        return false;
    };
    Field.buildField = function (conf, form) {
        var fieldHandler = Field.handlers.find(function (handler) { return handler.name === conf.type; });
        if (!fieldHandler) {
            (0, logger_1.logWarning)("No handler found for field name " + conf.name + " (type " + conf.type + ")");
            return;
        }
        var newField = fieldHandler.handler(conf, form);
        newField.form = form;
        newField._required = false;
        if (conf.validationRules) {
            conf.validationRules.forEach(function (rule) {
                rule.apply = Validators[rule.type];
                newField.addValidationRule(rule);
                if (rule.type === 'required') {
                    newField._required = true;
                }
            });
        }
        newField.isRequired = function () {
            if (!newField || !newField._required)
                return false;
            return newField._required;
        };
        if (conf.businessRules) {
            conf.businessRules.forEach(function (rule) {
                var listenableField = newField;
                var businessRulesHelper = BusinessRules;
                var action = businessRulesHelper[rule.action];
                listenableField.on(rule.trigger, newField.configuration.name + "-" + rule.action, function (f) { return action(f, rule.target); });
                if (rule.immediate) {
                    setTimeout(function () {
                        action(newField, rule.target);
                    }, 50);
                }
            });
        }
        return newField;
    };
    Field.prototype.show = function () {
        this._show = true;
        if (this.htmlElement) {
            this.htmlElement.classList.remove('ef-form-hidden');
        }
    };
    Field.prototype.hide = function () {
        this._show = false;
        if (this.htmlElement) {
            this.htmlElement.classList.add('ef-form-hidden');
        }
    };
    Field.prototype.addValidationRule = function (rule) {
        this._validationRules.push(rule);
    };
    Field.prototype._applySingleValidation = function (value, rule) {
        return __awaiter(this, void 0, void 0, function () {
            var returnValue, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        returnValue = {
                            outcome: true,
                            errorMessage: '',
                        };
                        if (typeof rule.apply !== 'function') {
                            return [2, returnValue];
                        }
                        if (!(rule.async && this.htmlElement)) return [3, 2];
                        this.htmlElement.classList.add('ef-form-validating');
                        this.form.htmlElement.classList.add('ef-form-validating');
                        _a = returnValue;
                        return [4, rule.apply(value, rule.value)];
                    case 1:
                        _a.outcome = _b.sent();
                        this.htmlElement.classList.remove('ef-form-validating');
                        this.form.htmlElement.classList.remove('ef-form-validating');
                        if (!returnValue.outcome) {
                            returnValue.errorMessage = rule.errorMessage;
                        }
                        return [3, 3];
                    case 2:
                        if (!rule.apply(value, rule.value)) {
                            returnValue.outcome = false;
                            returnValue.errorMessage = rule.errorMessage;
                        }
                        _b.label = 3;
                    case 3: return [2, returnValue];
                }
            });
        });
    };
    Field.prototype.validate = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var value_1, returnValue_1, promises_1, results, exc_1;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        value_1 = this.value;
                        returnValue_1 = {
                            outcome: true,
                            errorList: [],
                            fullTextErrorList: [],
                        };
                        if (!(this.htmlElement && this._show)) return [3, 2];
                        this.htmlElement.classList.remove('ef-form-valid', 'ef-form-invalid', '-is-valid', '-is-invalid');
                        if (this.form.settings.applyGud) {
                            (_a = this.htmlElement.querySelector('.ef-input-w')) === null || _a === void 0 ? void 0 : _a.classList.remove('ef-form-valid', 'ef-form-invalid', '-is-valid', '-is-invalid');
                        }
                        promises_1 = [];
                        this._validationRules.forEach(function (rule) { return promises_1.push(_this._applySingleValidation(value_1, rule)); });
                        return [4, Promise.all(promises_1)];
                    case 1:
                        results = _c.sent();
                        results.forEach(function (result) {
                            var errorMessage = result.errorMessage, outcome = result.outcome;
                            var _a = _this.configuration, label = _a.label, _b = _a.additionalData, additionalData = _b === void 0 ? {} : _b;
                            var errorLabel = additionalData.labelForErrors || label;
                            if (!outcome) {
                                returnValue_1.outcome = false;
                                returnValue_1.errorList.push(errorMessage);
                                returnValue_1.fullTextErrorList.push(errorLabel + ": " + errorMessage);
                            }
                        });
                        this.htmlElement.classList.add(returnValue_1.outcome ? 'ef-form-valid' : 'ef-form-invalid', returnValue_1.outcome ? '-is-valid' : '-is-invalid');
                        if (this.form.settings.applyGud) {
                            (_b = this.htmlElement.querySelector('.ef-input-w')) === null || _b === void 0 ? void 0 : _b.classList.add(returnValue_1.outcome ? 'ef-form-valid' : 'ef-form-invalid', returnValue_1.outcome ? '-is-valid' : '-is-invalid');
                        }
                        _c.label = 2;
                    case 2: return [2, returnValue_1];
                    case 3:
                        exc_1 = _c.sent();
                        throw new Error("Validation on field " + this.configuration.name + " failed: " + exc_1);
                    case 4: return [2];
                }
            });
        });
    };
    Field.prototype.triggerChangeEvent = function () {
        if (this.htmlElement) {
            (0, misc_1.triggerEvent)(this.htmlElement, 'change');
        }
    };
    Field.handlers = [];
    return Field;
}(Listenable_1.Listenable));
exports.default = Field;
