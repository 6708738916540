"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PLUGIN_LISTENER_NAME = void 0;
exports.PLUGIN_LISTENER_NAME = 'Forms X Local Storage';
var fetchConfigurationFromLocalStorage = function () {
    var oldConfigurationString;
    try {
        oldConfigurationString = localStorage.getItem('ef-AFData');
    }
    catch (e) {
        return;
    }
    if (!oldConfigurationString) {
        return {
            customer: {},
            extendedDetail: {},
        };
    }
    return JSON.parse(oldConfigurationString);
};
var capitalize = function (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
var callback = function (form) {
    if (form.settings.plugins && form.settings.plugins.disableLocalStorage) {
        return;
    }
    form.on('postsubmissionObjectReady', exports.PLUGIN_LISTENER_NAME, function () {
        var oldConfiguration = fetchConfigurationFromLocalStorage();
        if (!oldConfiguration) {
            return;
        }
        oldConfiguration.customer = __assign(__assign(__assign({}, oldConfiguration.customer), form.data.customer), { Neighborhood: form.data.neighborhood });
        if ('wantsToTravelSoon' in form.data) {
            oldConfiguration.extendedDetail.WantsToTravelSoon = form.data.wantsToTravelSoon;
        }
        if ('ProgramCode' in form.data.extendedDetail) {
            oldConfiguration.extendedDetail.LatestInquiryCode = form.data.extendedDetail.ProgramCode;
        }
        localStorage.setItem('ef-AFData', JSON.stringify(oldConfiguration));
    });
    form.on('formRendered', exports.PLUGIN_LISTENER_NAME, function () {
        var _a;
        var oldConfiguration = fetchConfigurationFromLocalStorage();
        if (!oldConfiguration) {
            return;
        }
        delete oldConfiguration.customer.CheckboxCombined;
        delete oldConfiguration.customer.Comments;
        if (Object.keys(oldConfiguration.customer).length === 0) {
            return;
        }
        var _loop_1 = function (i) {
            var componentName = capitalize(form.fields[i].configuration.name);
            if (oldConfiguration.customer[componentName]) {
                form.fields[i].value = oldConfiguration.customer[componentName];
                form.fields[i].triggerChangeEvent();
            }
            if (componentName === 'WantsToTravelSoon') {
                form.fields[i].value = oldConfiguration.extendedDetail.WantsToTravelSoon;
                form.fields[i].triggerChangeEvent();
            }
            if (componentName === 'BirthDate') {
                var dateOfBirth = new Date(oldConfiguration.customer['DateOfBirth']);
                var idate = {
                    year: dateOfBirth.getFullYear(),
                    month: dateOfBirth.getMonth() + 1,
                    day: dateOfBirth.getDate(),
                };
                form.fields[i].value = idate;
            }
            if (componentName === 'State') {
                var states_1 = [];
                (_a = document
                    .querySelector("select[name='state']")) === null || _a === void 0 ? void 0 : _a.querySelectorAll('option').forEach(function (n) {
                    states_1.push(n.value);
                });
                var selectedState = states_1.find(function (s) { return new RegExp("^" + oldConfiguration.customer['StateRegionCode']).test(s); });
                form.fields[i].value = selectedState;
            }
        };
        for (var i = 0; i < form.fields.length; i += 1) {
            _loop_1(i);
        }
    });
};
exports.default = callback;
