"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    endpoint: {
        dev: 'https://qa.ef.com/wwen/forms/{type}/{market}.json',
        qa: 'https://qa.ef.com/wwen/forms/{type}/{market}.json',
        prod: 'https://www.ef.com/wwen/forms/{type}/{market}.json',
        graphql: 'https://graphql-api.ef.com/',
        ageCategoriesApi: 'https://www.ef.com/central-api/common/agecategory/v2/getavailablecategories/{market}/',
        programsApi: 'https://www.ef.com/central-api/common/program/v2/getprograms/{market}/',
        qqProgramAllocationApi: 'https://www.ef.com/secureformsapi/QQGetProgramCode/?ProgramCategory={programCategory}&CustDateOfBirth={birthDate}&EFComMarketCode={market}&CountryCode={countryCode}',
        twilioApi: 'https://lookups.twilio.com/v2/PhoneNumbers/{value}',
    },
};
