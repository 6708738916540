"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Listenable = void 0;
var setOptionalArgumentDecorator_1 = require("./setOptionalArgumentDecorator");
var logger_1 = require("./logger");
var defaultPluginListenersNames = [
    require('../plugins/cn-addresses').PLUGIN_LISTENER_NAME,
    require('../plugins/kr-addresses').PLUGIN_LISTENER_NAME,
    require('../plugins/local-storage').PLUGIN_LISTENER_NAME,
    require('../plugins/tracking').PLUGIN_LISTENER_NAME,
    require('../plugins/v4-adapter').PLUGIN_LISTENER_NAME,
    require('../plugins/intl-phone-number').PLUGIN_LISTENER_NAME,
];
var Listenable = (function () {
    function Listenable() {
        this._listeners = [];
    }
    Object.defineProperty(Listenable.prototype, "listeners", {
        get: function () {
            return this._listeners;
        },
        enumerable: false,
        configurable: true
    });
    Listenable.prototype.on = function (eventType, name, cb) {
        this._listeners.push({
            eventType: eventType,
            name: name,
            cb: cb,
        });
        return this;
    };
    Listenable.prototype.removeListener = function (eType, lName) {
        var cbIndex = this._listeners.findIndex(function (_a) {
            var name = _a.name, eventType = _a.eventType;
            return name === lName && eventType === eType;
        });
        if (cbIndex >= 0) {
            this._listeners.splice(cbIndex, 1);
        }
        return this;
    };
    Listenable.prototype.removeDefaultPluginListeners = function () {
        this._listeners = this._listeners.filter(function (listener) { return !defaultPluginListenersNames.includes(listener.name); });
        console.log(this._listeners);
        return this;
    };
    Listenable.prototype._filterArray = function (eType) {
        var e_1, _a;
        var eTypeStr = eType;
        var prefiltered = [];
        var filtered = [];
        var postfiltered = [];
        try {
            for (var _b = __values(this._listeners), _c = _b.next(); !_c.done; _c = _b.next()) {
                var listener = _c.value;
                var listenerETypeStr = listener.eventType;
                if (listenerETypeStr === "pre" + eTypeStr) {
                    prefiltered.push(listener);
                }
                if (listenerETypeStr === eTypeStr) {
                    filtered.push(listener);
                }
                if (listenerETypeStr === "post" + eTypeStr) {
                    postfiltered.push(listener);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return __spreadArray(__spreadArray(__spreadArray([], __read(prefiltered), false), __read(filtered), false), __read(postfiltered), false);
    };
    Listenable.prototype._executeListeners = function (eType) {
        var _this = this;
        try {
            this._filterArray(eType).forEach(function (_a) {
                var cb = _a.cb;
                return typeof cb === 'function' && cb(_this);
            });
        }
        catch (exc) {
            (0, logger_1.logError)(exc);
        }
    };
    __decorate([
        (0, setOptionalArgumentDecorator_1.setOptionalArgument)({
            argumentIndex: 1,
            expectedType: 'string',
            fallbackValue: new Date().getTime().toString(),
        })
    ], Listenable.prototype, "on", null);
    return Listenable;
}());
exports.Listenable = Listenable;
