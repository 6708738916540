"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var programCodeProductCodeMap = {
    ILS: 'LS',
    ILSY: 'LS',
    ILSD: 'LS',
    LY: 'LY',
    HSY: 'HSY',
    IB: 'IA',
    BC: 'BC',
    LOC: 'LOC'
};
var programCodeToProductCode = function (programCode) {
    if (programCodeProductCodeMap[programCode])
        return programCodeProductCodeMap[programCode];
    return programCode;
};
exports.default = programCodeToProductCode;
